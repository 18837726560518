.eventLanding-main-container {
	height   : calc(100% - 204px); /*105px top-bar + 100px bottom-bar*/
	position : relative;
	display  : flex;
}

.eventLanding-video-container {
	width      : calc(100% - var(--inevent-sidebar-width));
	height     : 100%;
	position   : relative;
	background : var(--black-light);
	transition : width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.eventLanding-video-container.fullwidth {
	width : 100%;
}

.eventLanding-side-container {
	height   : 100%;
	position : relative;
}

.eventLanding-side-container.open {
	width : var(--inevent-sidebar-width);
}
