.playlist {
	height : 100%;
}

.playlist-container {
	padding    : 10px 20px 10px 30px;
	box-sizing : border-box;
	height     : calc(100% - 86px); /*height of upper area*/
	overflow   : auto;
}

.rtl .playlist-container {
	padding  : 10px 30px 10px 20px;
}
