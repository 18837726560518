.presenterInfo {
	display          : flex;
	flex-direction   : column;
	align-items      : center;
	width            : 100%;
	height           : 100%;
	justify-content  : center;
	gap              : 1%;
}

.presenterRow {
	display          : flex;
	width            : 100%;
	justify-content  : space-evenly;
}

.presenterImg {
	max-height : 7vh;
	max-width  : 7vh;
	object-fit: cover;
    border-radius: 50%
}

.presenterLabel {
	display          : flex;
	flex             : 1;
	flex-direction   : column;
	justify-content  : center;
	align-items      : center;
	min-width        : 7vh;
	max-width        : 12vh;
	gap              : 10px;
}

.presenterName {
	font-family   : var(--semibold);
	font-size     : 13px;
	color         : #f8f8f8;
	width         : 100%;
	overflow      : hidden;
	text-overflow : ellipsis;
	white-space   : nowrap;
	text-align    : center;
}
