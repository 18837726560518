@import url("spacing.css");
@import url("fonts/style.css");

::-webkit-scrollbar {
	width  : 6px!important;
	height : 6px!important;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,.2);
}

::-webkit-scrollbar-track {
	background: hsla(0,0%,100%,.1);
}

body {
  margin: 0;
  font-family: var(--regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

/*App Light theme colors*/

  --white                 : #FFFFFF;
  --dodger-blue           : #3F8CFF;                  /*App main primary color for light and dark mode*/
  --dodger-blue-20        : #3F8CFF33;                /*Tints-20 of primary*/
  --blue-blue             : #1863D3;                  /*Main secondary color for light and dark mode*/
  --black                 : #1C1C1C;
  --greyblue              : #8191A7;                  /*For small subtext*/
  --greyblue-30           : #8191A74D;
  --light-grey-blue       : #AFBAC9;                  /*For category text*/ 
  --light-grey-blue-20    : #AFBAC933;                /*Text of inactive buttons*/        
  --blue-blue-20          : #1863D333;
  --blue-blue-5           : #1863D30D;                /*Grey as circle for bg for buttons*/
  --blue-blue-60          : #1863D399;
  --light-periwinkle      : #DFE3E9;                  /*Bg color for inactive buttons*/ 
  --very-light-pink-75    : #CECECEBF;                /*Grey BG behind pop ups*/  
  --light-grey-blue-40    : #AFBAC966;                /*divider color*/
	
  /*Dark Mode*/

  --dodger-blue-32        : #3F8CFF52;                /*Tints-32 of primary*/
  --white-40              : #FFFFFF66;                /*Grey color used for small subtext*/
  --white-30              : #FFFFFF4D;
  --white-60              : #FFFFFF99;
  --grey                  : #868686;                  /*Grey color used for category text*/
  --dark-grey             : #686868;                  /*Dark grey For text of inactive buttons*/
  --white-10              : #FFFFFF1A;                /*Grey as circle BG for buttons*/
  --white-55              : #FFFFFF8C;
  --grey-light            : #272727;                  /*BG color for inactive buttons / Grey as circle BG for buttons*/
  --black-75              : #000000BF;                /*Grey BG behind popups*/
  --black-light           : #151515;                  /*dark grey color BG colors*/
  --white-light           : #F8F8F8;                  /*Colors not defined but used in many screesn so defining here*/
  --white-light-80        : #F8F8F8CC;
  --black-30              : #0000004D;                /*Not defined but used on many screens*/
  --black-40              : #00000066;
  --dark-black            : #282727;                  /*Not defined but used for disabled icons in DM*/
  --brownish-grey         : #707070;
  --brownish-grey-40      : #70707066;                /*used for divider color*/
  --brownish-grey-25      : #70707040;
  --dark-red              : #CC3D40;                  /*error color for dark*/
	
  /*Status Color*/

  --light-red             : #FE4D4F;                  /*Upcoming soon*/
  --kermit-green          : #5CA700;                  /*Ongoing*/
  --mango                 : #FFA238;                  /*Upcoming*/
  --blue-grey             : #909191;                  /*completed-light-mode*/
  --blue-grey-dark        : #5B5B5B;                  /*completed-dark-mode*/

  /*Pie chart*/

  --pale-teal             : #83D49D;                  /*option 1*/
  --pale-voilet           : #C9ADFF;                  /*option 2*/
  --robin-s-egg-blue      : #A2E9FF;                  /*option 3*/
  --pale-peach            : #FFE5B4;                  /*option 4*/

/*App font familiy*/

  --regular  : Open Sans Regular;
  --semibold : Open Sans Semibold; 
  --bold     : Open Sans Bold;

/*Global variables*/
   --inevent-sidebar-width    : 425px;
   --inevent-bottombar-height : 101px;
   --inevent-topbar-height    : 105px;
   --backdrop                 : rgba(0, 0, 0, 0.5);
}

.root-parent {
	height      : 100%;
	width       : 100%; 
	position    : absolute;
	margin      : 0px;
	background  : var(--white);
	overflow    : hidden auto;
}

.dark .root-parent {
	background : var(--black);
}

.page-parent {
	height     : 100%;
	max-height : 100%;
	overflow   : hidden;
}

.page-container {
	height     : 100%;
	overflow   : hidden auto;
	box-sizing : border-box;
}

.header + .page-container {
	height  : calc(100% - 82px);
}
