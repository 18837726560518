.notification  {
	position         : fixed;
	bottom           : calc(var(--inevent-bottombar-height) + var(--xl));
	right            : var(--xl);
	box-shadow       : 0 3px 19px 0 rgba(70, 67, 95, 0.15);
	border           : solid 1px rgba(63, 140, 255, 0.05);
	background-color : var(--white);
	border-radius    : 12px;
	width            : 401px;
	z-index          : 100000;
}

.dark .notification {
	background-color : var(--black);
	box-shadow       : 0 3px 12px 0 rgba(0, 0, 0, 0.2);
	border           : solid 1px #313131;
}

.rtl .notification {
	right : inherit;
	left  : var(--xl)
}

.notification-header {
	padding : 20px 20px 16px 20px;
}

.notification-view-btn {
	background-color : var(--blue-blue);
}
