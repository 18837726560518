.callOut {
	--arrow-left : 5%;
}

.callOut {
	border-radius : 15px;
	box-shadow    : 0 3px 19px 0 rgba(70, 67, 95, 0.15);
	position      : relative;
	max-width     : 410px;
}

.dark .callOut {
	background-color : var(--black);
	box-shadow       : none;
	border           : solid 1px #313131; 
}

.callOut::after {
	content       : "";
    display       : block;
    border-width  : 10px 10px 0;
    border-style  : solid;
    border-color  : var(--white) transparent transparent;
    position      : absolute;
	z-index       : 10;
}

.dark .callOut::after {
	display          : inline-block;
	width            : 10px;
	height           : 10px;
	border-top       : 1px solid #313131;
	border-right     : 1px solid #313131;
	background-color : var(--black);
	border-style     : solid;
	border-color     : none;
	border-width     : inherit;
	transform        : rotate(135deg);
}

.callOut.bottom-start::after {
    left          : 5%;
    bottom        : -10px;
}

.rtl .callOut.bottom-start::after {
	left         : calc(100% - var(--arrow-left));
}

.dark .callOut.bottom-start::after {
	bottom : -6.5px;
}

.callOut.bottom-center::after {
	left   : var(--arrow-left);
	bottom : -6.5px;
}

.rtl .callOut.bottom-center::after {
	left   : calc(100% - var(--arrow-left));
}