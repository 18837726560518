.MuiIconButton-root.size-15 {
	width      : 15px;
	height     : 15px;
}

.MuiIconButton-root.size-21 {
	width      : 21px;
	height     : 21px;
}

.MuiIconButton-root.size-25 {
	width      : 25px;
	height     : 25px;
}

.MuiIconButton-root.size-30 {
	width      : 30px;
	height     : 30px;
}

.MuiIconButton-root.size-35 {
	width  : 35px;
	height : 35px;
}

.MuiIconButton-root.size-40 {
	width  : 40px;
	height : 40px;
}

.MuiIconButton-root.size-45 {
	width  : 45px;
	height : 45px;
}

.MuiIconButton-root.size-50 {
	width  : 50px;
	height : 50px;
}
