.pie-graph {
	height     : 350px;
	position   : relative;
}

.pie-total {
	font-size : 26px;
}

.pie-label {
	display   : inline-block;
	position  : absolute;
	top       : 50%;
	left      : 50%;
	transform : translate(-50%, -50%);
}

.pie-legend-opt {
	padding       : 20px 0px;
	border-bottom : solid 1px rgba(175, 186, 201, 0.4);
}

.pie-legend-opt:first-child {
	padding : 0px 0px 20px 0px;
}

.pie-legend-opt:last-child {
	padding       : 20px 0px 0px 0px;
	border-bottom : none;
}

.pie-legend-icon {
	display    : inline-block;
	width      : 16px;
	min-width  : 16px;
	height     : 10px;
	min-height : 10px;
	margin     : 6px 10px 0px 0px;
}

.rtl .pie-legend-icon {
	margin  : 6px 0px 0px 10px;
}
