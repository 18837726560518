body {
	--xxs  : 2px;
	--xs   : 7px;
	--s    : 8px;
	--m    : 10px;
	--l    : 15px;
	--xl   : 20px;
	--xxl  : 30px;
	--xxl2 : 40px;
}

.m-xxs {
	margin : var(--xxs) !important;
}

.m-xs {
	margin : var(--xs) !important;
}

.m-s {
	margin : var(--s) !important;
}

.m-m {
	margin : var(--m) !important;
}

.m-l {
	margin : var(--l) !important;
}

.m-xl {
	margin : var(--xl) !important;
}

.m-xxl {
	margin : var(--xxl) !important;
}

.m-xxl2 {
	margin : var(--xxl2) !important;
}

.mt-xxs {
	margin-top : var(--xxs) !important;
}

.mt-xs {
	margin-top : var(--xs) !important;
}

.mt-s {
	margin-top : var(--s) !important;
}

.mt-m {
	margin-top : var(--m) !important;
}

.mt-l {
	margin-top : var(--l) !important;
}

.mt-xl {
	margin-top : var(--xl) !important;
}

.mt-xxl {
	margin-top : var(--xxl) !important;
}

.mt-xxl2 {
	margin-top : var(--xxl2) !important;
}

.mr-xxs {
	margin-right : var(--xxs) !important;
}

.mr-xs {
	margin-right : var(--xs) !important;
}

.mr-s {
	margin-right : var(--s) !important;
}

.mr-m {
	margin-right : var(--m) !important;
}

.mr-l {
	margin-right : var(--l) !important;
}

.mr-xl {
	margin-right : var(--xl) !important;
}

.mr-xxl {
	margin-right : var(--xxl) !important;
}

.mr-xxl2 {
	margin-right : var(--xxl2) !important;
}

.mb-xxs {
	margin-bottom : var(--xxs) !important;
}

.mb-xs {
	margin-bottom : var(--xs) !important;
}

.mb-s {
	margin-bottom : var(--s) !important;
}

.mb-m {
	margin-bottom : var(--m) !important;
}

.mb-l {
	margin-bottom : var(--l) !important;
}

.mb-xl {
	margin-bottom : var(--xl) !important;
}

.mb-xxl {
	margin-bottom : var(--xxl) !important;
}

.mb-xxl2 {
	margin-bottom : var(--xxl2) !important;
}

.ml-xxs {
	margin-left : var(--xxs) !important;
}

.ml-xs {
	margin-left : var(--xs) !important;
}

.ml-s {
	margin-left : var(--s) !important;
}

.ml-m {
	margin-left : var(--m) !important;
}

.ml-l {
	margin-left : var(--l) !important;
}

.ml-xl {
	margin-left : var(--xl) !important;
}

.ml-xxl {
	margin-left : var(--xxl) !important;
}

.ml-xxl2 {
	margin-left : var(--xxl2) !important;
}

.p-xxs {
	padding : var(--xxs) !important;
}

.p-xs {
	padding : var(--xs) !important;
}

.p-s {
	padding : var(--s) !important;
}

.p-m {
	padding : var(--m) !important;
}

.p-l {
	padding : var(--l) !important;
}

.p-xl {
	padding : var(--xl) !important;
}

.p-xxl {
	padding : var(--xxl) !important;
}

.p-xxl2 {
	padding : var(--xxl2) !important;
}

.pt-xxs {
	padding-top : var(--xxs) !important;
}

.pt-xs {
	padding-top : var(--xs) !important;
}

.pt-s {
	padding-top : var(--s) !important;
}

.pt-m {
	padding-top : var(--m) !important;
}

.pt-l {
	padding-top : var(--l) !important;
}

.pt-xl {
	padding-top : var(--xl) !important;
}

.pt-xxl {
	padding-top : var(--xxl) !important;
}

.pt-xxl2 {
	padding-top : var(--xxl2) !important;
}

.pr-xxs {
	padding-right : var(--xxs) !important;
}

.pr-xs {
	padding-right : var(--xs) !important;
}

.pr-s {
	padding-right : var(--s) !important;
}

.pr-m {
	padding-right : var(--m) !important;
}

.pr-l {
	padding-right : var(--l) !important;
}

.pr-xl {
	padding-right : var(--xl) !important;
}

.pr-xxl {
	padding-right : var(--xxl) !important;
}

.pr-xxl2 {
	padding-right : var(--xxl2) !important;
}

.pb-xxs {
	padding-bottom : var(--xxs) !important;
}

.pb-xs {
	padding-bottom : var(--xs) !important;
}

.pb-s {
	padding-bottom : var(--s) !important;
}

.pb-m {
	padding-bottom : var(--m) !important;
}

.pb-l {
	padding-bottom : var(--l) !important;
}

.pb-xl {
	padding-bottom : var(--xl) !important;
}

.pb-xxl {
	padding-bottom : var(--xxl) !important;
}

.pb-xxl2 {
	padding-bottom : var(--xxl2) !important;
}

.pl-xxs {
	padding-left : var(--xxs) !important;
}

.pl-xs {
	padding-left : var(--xs) !important;
}

.pl-s {
	padding-left : var(--s) !important;
}

.pl-m {
	padding-left : var(--m) !important;
}

.pl-l {
	padding-left : var(--l) !important;
}

.pl-xl {
	padding-left : var(--xl) !important;
}

.pl-xxl {
	padding-left : var(--xxl) !important;
}

.pl-xxl2 {
	padding-left : var(--xxl2) !important;
}
