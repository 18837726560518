.profile {
	/*variables*/
	--camera-icon-size : 35px;
	--profile-size     : 127px;

	margin : auto;
}

.profile-container {
	position         : relative;
}

.profile-avatar {
	width  : var(--profile-size);
	height : var(--profile-size);
}

.profile-camera-icon-container {
	position         : absolute;
	bottom           : calc( ( var(--camera-icon-size ) ) / -2 ) ;
	left             : 50%;
	transform        : translate(-50%);
}

.profile-option-main {
	text-align : center;
}

