.pollModal .MuiPaper-root {
	padding : var(--xxl);
	width   : 422px;
}

.pollModal-question {
	font-family   : var(--regular);
	margin-bottom : var(--xs);
}

.pollModal-question-body {
	font-size     : 15px;
	margin-bottom : var(--xxl);
}

.pollModal .title {
	font-size   : 22px;
	color       : var(--grey-two);   
	font-weight : bold;
}

.pollModal .submit-btn {
	min-width : 50%;
}

.pollModal .graph-icon {
	padding : 0px;
}

.pollModal-graph-container {
	margin-top : var(--xl);
}

.graph-icon svg {
	height : 36px;
	width  : 36px;
}

.pollModal-graph-icon {
	height          : 40px;
	width           : 40px;
	display         : flex;
	justify-content : center;
	align-items     : center;
	border          : 1px solid var(--blue-blue);
	border-radius   : 0px 5px 5px 0px;
}

.dark .pollModal-graph-icon {
	border : 1px solid var(--dodger-blue);
}

.pollModal-graph-icon:first-child {
	border-radius   : 5px 0px 0px 5px;
}

.pollModal-graph-icon:last-child {
	border-radius   : 0px 5px 5px 0px;
}

.rtl .pollModal-graph-icon:first-child {
	border-radius   : 0px 5px 5px 0px;
}

.rtl .pollModal-graph-icon:last-child {
	border-radius   : 5px 0px 0px 5px;
}

.pollModal-graph-icon.selected {
	background: var(--blue-blue);
}

.dark .pollModal-graph-icon.selected {
	background : var(--dodger-blue);
}
