.imgCaptureModal {                                                                                                                                      
	position        : relative;
    width           : 100%;
    height          : 312px;
    display         : flex;
    justify-content : center;
    align-items     : center;
	overflow        : hidden;
	margin-bottom   : 35px;
}

.imgCaptureModal-video-container {
	position : absolute;
    width    : 720px;
}
