.liveControl {
	position         : absolute;
	z-index          : 100;
	display          : flex;
	align-items      : center;
	padding          : var(--xl);
	border-radius    : 15px;
	left             : calc(50% - 166px); /*subtract half width of container*/
	bottom           : var(--xxl);
	box-shadow       : 0 3px 19px 0 rgba(0, 0, 0, 0.06);
	border           : solid 1px var(--blue-blue-5);
	background-color : var(--white);
}

.dark .liveControl {
	border           : solid 1px #313131;
	background-color : var(--black-light);
	box-shadow       : none;
}

.liveControl-drag {
	cursor  : move;
}

.liveControl .MuiButtonBase-root {
	margin-left : var(--xl); 
}

.rtl .liveControl .MuiButtonBase-root {
	margin-right : var(--xl);
	margin-left  : 0px;
}