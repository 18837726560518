.eventInfoModal {
	z-index: 1000;
}

.eventInfoModal-presenter {
	font-family : var(--regular);
}

.eventInfoModal-bottom-left {
	border-right: 1px solid var(--light-grey-blue-40);
}


.dark .eventInfoModal-bottom-left {
	border-right :  1px solid var(--brownish-grey-40);
}

.eventInfoModal-bottom-right {
	max-height : 300px;
	overflow   : auto;
}