.circularProgress.container {
	width    : 200px;
	height   : 200px;
	position : relative;
}

.circularProgress-label {
	font-size   : 40px;
	font-family : var(--semibold);
    display     : inline-block;
    position    : absolute;
    top         : 50%;
    left        : 50%;
    transform   : translate(-50%, -50%);
}

.dark .circularProgress-label {
	color : var(--white);
}

.circularProgress.checked {
	display       : inline-block;
	height        : 55px;
	width         : 22px;
	border-bottom : 4px solid var(--blue-blue);
	border-right  : 4px solid var(--blue-blue);
    position      : absolute;
    top           : 50%;
    left          : 50%;
    transform     : translate(-50%, -50%) rotate(45deg);
}

.dark .circularProgress.checked {
	border-bottom : 4px solid var(--dodger-blue);
	border-right  : 4px solid var(--dodger-blue);
}