.login-badge {
	margin : auto;
}

.login-image-container {
	background-image    : -webkit-image-set(url(assets/images/loginImage.png) 1x, url(assets/images/loginImage@2x.png) 2x,url(assets/images/loginImage@3x.png) 3x);
	background-size     : cover;
	background-position : center;
	padding             : 40px 50px;
	position            : relative;
}

.login-image-grid {
	display       : flex;
	margin-top    : var(--xxl);
	margin-bottom : var(--xxl2);
}

.login-form-container {
	max-width : 285px;
	margin    : 0px auto;
}

.login-image-title {
	color : var(--white-55);
}

.login-image-main-text {
	font-family    : var(--bold);
	font-size      : 28px;
	font-stretch   : normal;
	font-style     : normal;
	line-height    : 1.46;
	letter-spacing : normal;
	color          : var(--white);
	margin-top     : var(--xl);
}

.dark .login-image-main-text {
	color : var(--white-light-80);
}

.login-image-info {
	max-width : 100%;
	z-index   : 10;
	position  : relative;
}

.dark .login-image-overlay {
	background-color : var(--black-40);
	background-image : none;
}

.login-right-container {
	padding : 40px 34px;
}

.login-top-container {
	flex       : 1;
	max-width  : 100%;
	min-height : 100px;
}

.login-lang-title {
	font-family    : var(--bold);
	text-transform : uppercase;
	color          : var(--light-grey-blue);
}
