.imgCropModal-crop-container {
	position : relative;
	height   : 260px;
	width    : 362px;
}

.imgCropModal-info-text {
	text-align  : center;
	font-family : var(--semibold);
	font-weight : 600;
}

.imgCropModal-zoom-container {
	display     : flex;	
	padding     : 24px 0px 35px 0px;
	align-items : center;
}
