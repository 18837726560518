.eventLandingBottomBar-container {
	box-shadow       : 0 3px 19px 0 rgba(0, 0, 0, 0.06);
	border-top       : solid 1px var(--blue-blue-5);
	background-color : var(--white);
	padding          : 25px var(--xxl) 25px var(--xxl2);
	max-height       : var(--inevent-bottombar-height);
	height           : var(--inevent-bottombar-height);
	overflow         : auto;
}

.dark .eventLandingBottomBar-container {
	background-color : var(--black);
	border-top       : none;
	box-shadow       : none;
}

.eventLandingBottomBar-event-title {
	font-family : var(--semibold);
}

.eventLandingBottomBar-ask-btn {
	width            : 167px;
	padding-left     : 30px;
	padding-right    : 30px;
	background-color : var(--blue-blue);
}

.eventLandingBottomBar-action-btn .MuiIconButton-colorPrimary {
	background-color : var(--blue-blue);
}

.eventLandingBottomBar-ask-btn img {
	margin-right : var(--s);
}

.rtl .eventLandingBottomBar-ask-btn img {
	margin-left : var(--s);
}

