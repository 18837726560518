.landing-right-container {
	width    : calc(100% - 114px);
	height   : 100%;
	overflow : auto;
}

.landing-top-bar {
	height        : 120px;
	padding-left  : 26px;
	border-bottom : solid 1px var(--brownish-grey-25);
}

.rtl .landing-top-bar {
	padding-right : 26px;
}

.landing-main-container {
	padding : 30px 45px 30px 24px;
}

.rtl .landing-main-container {
	padding : 30px 24px 30px 45px;
}

.landing-main-container.online {
	display : flex;
	height  : calc(100% - 180px) ;
}

.landing-pin-container{
	border        : 1px solid var(--brownish-grey-25);
    padding       : 80px var(--xxl);
    width         : 50%;
    margin        : auto;
    min-width     : 500px;
    max-width     : 500px;
    border-radius : 15px;
}