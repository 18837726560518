.requestCard {
	padding        : var(--xl) 0px;
	box-sizing     : border-box;
	border-bottom  : solid 1px var(--light-grey-blue-40);
}

.requestCard:last-child {
	border-bottom : none;
}

.dark .requestCard {
	border-bottom : 1px solid rgba(175, 186, 201, 0.1);
}

.dark .requestCard:last-child {
	border-bottom : none;
}

.dark .requestCard-title {
	color : #EBEBEB;
}

.requestCard-time {
	font-family : var(--regular); 
}

.requestCard-status {
	font-family : var(--regular);
}

.requestCard-status.pending {
	color : var(--mango);
}

.requestCard-statusIcon {
    width            : 6px;
    height           : 6px;
    border-radius    : 100%;
    display          : inline-block;
    margin           : 0px 6px;
    position         : relative;
    bottom           : 1px;
}

.requestCard-status.pending .requestCard-statusIcon {
	background : var(--mango);
}

.requestCard-status.accepted {
	color : var(--kermit-green);
}

.requestCard-status.accepted .requestCard-statusIcon {
	background : var(--kermit-green);
}

.dark .requestCard-content {
	color : #FFFFFF99
}

.requestCard-cancel-btn {                                                                                                                                                                                        
	font-size   : 13px;
	font-family : var(--bold);
}           
