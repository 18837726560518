.landingLeftBar {
	padding      : 47px 19px 40px 24px;
	box-sizing   : border-box;
	width        : 113px;
    border-right : solid 1px var(--brownish-grey-25);
	height       : 100%;
}

.rtl .landingLeftBar {
    border-left : solid 1px var(--brownish-grey-25);
	border-right : none;
}

.landingLeftBar-bottom-container {
	max-width : 100%;
}

.landingLeftBar-profile {
	width         : 40px;
	height        : 40px;
	margin-bottom : var(--xl);
	cursor        : pointer;
}
