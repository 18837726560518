.settings-container {
	border-radius    : 8px;
	min-width        : 147px;
	opacity          : 1;
	box-shadow       : 0 3px 19px 0 #46435F26;
	border           : solid 1px #3F8CFF0D;
	background-color : var(--white);
}

.dark .settings-container {
	box-shadow       : 0 3px 6px 0 #00000042;
	border           : solid 1px #313131;
	background-color : var(--black);
}

.settings-popper {
	z-index : 1000;
}

.settings-logout {
	border-bottom : none;
	color         : var(--light-red);
}
