.comments {
	height : 100%;
}

.comments-container {
	height        : calc(100% - 206px);
	overflow      : auto;
	padding       : var(--xl);
	box-sizing    : border-box;
}

.comments-container.isPast {
	height : calc(100% - 86px);
}

.comments-commentBox-container {
	position : absolute;
	bottom   : 0px;
	width    : 100%;
}

.dark .comments-bottom-divider {
	background-image : linear-gradient(to bottom, rgba(28, 28, 28, 0), #121212);
}
