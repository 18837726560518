.eventCard {
	padding          : 26px 30px 26px 20px;
	box-shadow       : 0 3px 15px 0 rgba(0, 0, 0, 0.06);
	background-color : var(--white);
	border-radius    : 8px;
	margin-top       : var(--xs);
}

.eventCard:first-child {
	margin-top : 0px;
}

.dark .eventCard {
	background : var(--black-light);
	box-shadow : 0 3px 6px 0 var(--black-16);
}

.eventCard > div {
	padding : 0px 14px 0px 0px;
}

.rtl .eventCard > div {
	padding : 0px 0px 0px 14px;
}

.eventCard-status {
	color       : var(--kermit-green);
	font-family : var(--regular);
}

.eventCard-statusIcon {
	width            : 6px;
	height           : 6px;
	border-radius    : 100%;
	background-color : var(--kermit-green);
	display          : inline-block;
	margin-right     : 6px;
	position         : relative;
	bottom           : 1px;
}

.rtl .eventCard-statusIcon {
	margin-left  : 6px;
	margin-right : 0px;
}

.eventCard-time {
	display         : flex;
	justify-content : flex-end;
	align-items     : center;
	color           : var(--greyblue);
}

.dark .eventCard-time {
	color : #727272;
}

.eventCard-desc {
	font-family : var(--regular);
	margin-top  : 4px;
}

.dark .eventCard-title {
	color : #F5F4F4; 
}

.eventCard-timeago.ongoing {
	color : var(--kermit-green);	
}

.eventCard-timeago.not_started{
	color : var(--blue-grey);	
}

.eventCard-timeago.completed {
	color : var(--greyblue);
}

.dark .eventCard-timeago.completed {
	color : var(--blue-grey-dark);	
}

.dark .eventCard-timeago.not_started {
	color : var(--blue-grey-dark);	
}

.eventCard-timeago.upcoming {
	color : var(--mango);	
}

.eventCard-timeago.upcoming_soon {
	color : var(--light-red);	
}

.eventCard-presenter-avatar {
	width  : 30px;
	height : 30px;
}

.eventCard-join-btn {
	background-color : var(--blue-blue);
}

.eventCard-view-btn {
	border-color     : var(--blue-blue);
	color            : var(--blue-blue);
	background-color : inherit;
}
