.polls {
	height : 100%;
}

.polls-container {
	padding    : 10px 20px 10px 30px;
	height     : calc(100% - 131px); /*height of upper area*/
	overflow   : auto;
	box-sizing : border-box;
}

.polls-container.isPast {
	height   : calc(100% - 86px); /*reduced height of tab area*/
}

.rtl .polls-container {
	padding  : 10px 30px 10px 20px;
}
