.alert-container .MuiDialog-paper {
	min-width  : 500px;
	width      : 508px;
	padding    : var(--xxl);
	box-sizing : border-box;
}

.alert-container.sideAlert {
	box-shadow    : 0 3px 19px 0 rgba(70, 67, 95, 0.15);
	border        : solid 1px var(--light-grey-blue-40);
	border-radius : 15px;
	background    : var(--white);
	width         : 320px;
	box-sizing    : border-box;
}

.sideAlert-backdrop {
	background-color : var(--backdrop);
	position         : absolute;
	top              : 0px;
	left             : 0px;
	display          : flex;
	align-items      : center;
	justify-content  : center;
	width            : 100%;
	height           : 100%;
}

.dark .alert-container.sideAlert {
	background : var(--black-light);
	border     : solid 1px #313131;
	box-shadow : none;
}

.alert-dismiss-btn {
	margin-right : var(--m);
}

.rtl .alert-dismiss-btn {
	margin-left  : var(--m);
	margin-right : 0px;
}

.alert-container.sideAlert button {
	font-size   : 13px; 
	font-family : var(--bold);
}