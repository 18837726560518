.commentCard {
	padding       : 0px 0px 20px 0px;
	box-sizing    : border-box;
	border-bottom : solid 1px var(--light-grey-blue-40);
	margin-top    : 13px;
}

.commentCard:first-child {
	margin-top : 10px;
}

.dark .commentCard {
	border-bottom : 1px solid rgba(175, 186, 201, 0.4); 
}

.commentCard:last-child {
	border-bottom  : none;
	padding-bottom : 0px;
}

.commentCard-avatar {
	width  : 30px;
	height : 30px;
}

.commentCard-name {
	font-family : var(--bold);
	margin      :  0px var(--m);
}
