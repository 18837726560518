.bars-container {
	margin   : 20px 0px;
	position : relative;
}

.bars-container:first-child {
	margin : 0px;
}

.bars-container:last-child {
	margin-bottom : 0px !important;
}

.bars-container:first-child {
	margin : 0px;
}

.bars-container.checkbox {
	margin  : 20px 0px;
}

.bars-container.checkbox:first-child {
	margin  : 0px;
}

.bars-container.checkbox .MuiCheckbox-root {
	padding : 0px;
	margin  : 0px 15px 0px 0px;
}

.bars.parent {
    padding       : 14px 15px;
    border-radius : 8px;
	cursor        : pointer;
	border        : solid 1px var(--blue-blue-20);
	position      : relative;
}

.dark .bars.parent {
	border : solid 1px var(--white-40);
}

.bars.selected {
	border           : solid 1px var(--dodger-blue);
	background-color : var(--dodger-blue-20);
}

.dark .bars.selected {
	border           : solid 1px var(--dodger-blue);
	background-color : var(--dodger-blue-32);
}

.bars.selected .bars-title {
	color       : var(--blue-blue);
	font-family : var(--semibold);
}

.dark .bars.selected .bars-title {
	color : var(--white);
}

.bars.selected .bars-title.shared {
	color       : var(--blue-blue);
	font-family : var(--semibold);
}

.dark .bars.selected .bars-title.shared {
	color : var(--white);
}

.bars-percent {
	font-family : var(--semibold);
}

.dark .bars.selected .bars-percent {
	color : var(--white);
}

.bars-opt-no {
	color         : var(--greyblue);
	font-family   : var(--bold);
	margin-bottom : 8px;
}

.dark .bars-opt-no {
	color         : var(--grey);
}

.dark .bars-title {
	color : var(--white-55);
}

.dark .bars-title.selected {
	color : var(--white);
}
