.videoPlayer.parent {
	height      : 100%;
	position    : relative;
}

.rtl .videoPlayer.parent {
}

.videoPlayer-icon.volume {
	right : 25px;
}

.rtl .videoPlayer-icon.volume {
	left  : 25px;
	right : inherit;
}

.videoPlayer-icon.fullscreen {
	right    : 65px;
}

.rtl .videoPlayer-icon.fullscreen {
	left  : 65px;
	right : inherit;
}

.videoPlayer-icon {
	z-index  : 1000;
	position : absolute;
	bottom   : 25px;
}

.videoPlayer-icon.close-fullscreen {
	right  : 25px;
	top    : 25px;
	bottom : inherit;
}

.rtl .videoPlayer-icon.close-fullscreen {
	left   : 25px;
}
