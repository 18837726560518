.pinInput-box.MuiTextField-root .MuiInputBase-root {
	font-size    : 16px;
	font-weight  : 600;
	line-height  : 1.38;
	color        : #000000;
	margin-right : 10px;
}

.pinInput-box.length-6.MuiTextField-root .MuiInputBase-root {
	width  : 45px;
	height : 45px;
}

.pinInput-box.length-4.MuiTextField-root .MuiInputBase-root {
	width  : 48px;
	height : 50px;
}

.pinInput-box.MuiTextField-root .MuiInputBase-root .MuiInputBase-input {
	text-align : center;
}

.pinInput-invalid-pin {
	float        : right;
	margin-right : 10px;
	margin-top   : 8px;
}

