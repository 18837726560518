.poll-icon-big {
	border-radius : 5px;
	border        : solid 2px rgba(129, 145, 167, 0.2);
	display       : inline-flex;
	position      : relative;
}

.poll-icon-col {
	border-radius : 5px;
	border        : solid 2px var(--blue-blue);
	position      : absolute;
	bottom        : -2px;
	left          : -2px;
	display       : inline-flex;
}

.dark .poll-icon-big {
	border : solid 2px var(--white-10);
}

.dark .poll-icon-col {
	border : solid 2px var(--dodger-blue);
}

.poll-icon-big.first {
	width         : 20px;
	height        : 38px;
}

.poll-icon-col.first {
	width         : 100%;
	height        : 13px;
}

.poll-icon-big.second {
	width         : 20px;
	height        : 105px;
	margin-left   : 15px;
	margin-right  : 15px;
}

.poll-icon-col.second {
	width         : 20px;
	height        : 105px;
}

.poll-icon-big.third {
	width         : 20px;
	height        : 76px;
}

.poll-icon-col.third {
	width         : 100%;
	height        : 34px;
}

.pollSubmitModal-close-btn {
	border     : solid 1px var(--greyblue);
	font-size  : 13px;
	width      : 125px;
	color      : var(--greyblue);
}

.dark .pollSubmitModal-close-btn {
	border     : solid 1px #444342;
	color      : #c3c3c3;
}
