.walkthrough-backdrop {
	position         : fixed;
	height           : 100%;
	width            : 100%;
	left             : 0px;
	top              : 0px;
	background-color : var(--backdrop);
	z-index          : 1000;
}

.walkthrough-popper {
	z-index: 1000;
}

.walkthrough-paper {
	padding  : 15px 20px 20px 20px;
	width    : 315px;
	position : relative;
	z-index  : 1000;
}

.walkthrough-title {
	font-size   : 15px;
	font-family : var(--bold);
}

.walkthrough-btn {
	min-width : auto;
	padding   : 0px;
	font-size : 13px;
}

.walkthrough-btn.skip {
	font-family : var(--semibold);
	color       : var(--greyblue);
}

.dark .walkthrough-btn.skip {
	color : var(--white-40);
}

.walkthrough-btn.next {
	font-family : var(--bold);
	color       : var(--blue-blue);
}

.dark .walkthrough-btn.next {
	color : var(--dodger-blue);
}