.searchField .MuiOutlinedInput-root {
	width         : 285px;
	height        : 40px;
	border-radius : 20px;
    box-shadow    : 0 3px 11px 0 rgb(116 116 116 / 10%);
}

.dark .searchField .MuiOutlinedInput-root {
	box-shadow : none;
	background : var(--black-light);
}

.searchField fieldset {
	border : none !important;
}

.searchField .Mui-focused {
	border : solid 1px var(--blue-blue-20);
}

.dark .searchField .Mui-focused {
	border : solid 1px var(--white-30);
}

.searchField .Mui-focused input {
	font-family : var(--regular);
	color       : var(--black);
}

.dark .searchField .Mui-focused input {
	color : var(--white);
}

.searchField input {
	font-family    : var(--semibold);
	font-size      : 14px;
	font-weight    : normal;
	font-stretch   : normal;
	font-style     : normal;
	line-height    : 1.36;
	letter-spacing : normal;
	color          : var(--grey);
}

.dark .searchField input {
	color : var(--white);
}

.searchField input::placeholder {
	font-family : var(--regular);
	color       : var(--grey);
}

.dark .searchField input::placeholder {
	color :  #535353;
}
