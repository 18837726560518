.count {
	display         : inline-block;
	height          : 23px;
	font-size       : 11px;
	font-stretch    : normal;
	font-style      : normal;
	line-height     : 1.36;
	letter-spacing  : normal;
	font-family     : var(--semibold);
	display         : flex;
	justify-content : center;
	align-items     : center;
}

.count.primary {
	border : solid 1px var(--light-grey-blue);
	color  : var(--light-grey-blue);
}

.count.secondary {
	border : solid 1px var(--greyblue);
	color  : var(--greyblue);
}

.dark .count.primary,.dark .count.secondary {
	border : solid 1px #6E6E6E;
	color  : #6E6E6E;
}

.count.circular {
	border-radius   : 100%;
	width           : 23px;
}

.count.elliptical {
	width         : 36px;
	border-radius : 12px;
}
