.sentRequest {
	height : 100%;
}

.sentRequest-container {
	padding    : 10px 20px 10px 30px;
    box-sizing : border-box;
    height     : calc(100% - 86px);
    overflow   : auto;
}

.rtl .sentRequest-container {
	padding : 10px 30px 10px 20px;
}
