.pollCard.parent {
	padding          : 20px 0px;
	border-bottom    : solid 1px var(--light-grey-blue-40);
}

.dark .pollCard.parent {
	border-bottom : solid 1px var(--brownish-grey-40);
}

.pollCard-question {
	font-family : var(--bold);
}

.pollCard-result-pending {
	font-family   : var(--semibold);
	color         : var(--mango);
	padding-left  : var(--s);
	border-left   : solid 2.5px var(--mango);
	margin-bottom : var(--m);
}

.pollCard-state.closed {
	color : var(--greyblue);
}

.dark .pollCard-state.closed {
	color : #727272;
}

.pollCard-state.live {
     color       : var(--kermit-green);
     font-family : var(--regular);
 }

.pollCard-statusIcon {
     width            : 6px;
     height           : 6px;
     border-radius    : 100%;
     background-color : var(--kermit-green);
     display          : inline-block;
     margin-right     : 6px;
     position         : relative;
     bottom           : 1px;
}

.rtl .pollCard-statusIcon {
     margin-left  : 6px;
     margin-right : 0px;
 }

.pollCard-statusIcon.closed {
     background-color : var(--greyblue);
}

.dark .pollCard-statusIcon.closed {
     background-color : #727272;
}
