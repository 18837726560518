.popups-medium .MuiPaper-root {
	max-width : 427px;
	position  : relative;
}

.popups-medium-close {
	position : absolute;
	right    : 29px;
	top      : 24px;
}

.popups-title-container {
	padding : var(--xxl);
}

.popups-main-container {
	padding : var(--xxl);
}
