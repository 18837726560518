.eventLandingSideBar .MuiDrawer-paper {
	width         : 100%;
	height        : calc(100% - 2px);
	position      : absolute;
	border-top    : 1px solid #313131;
	border-bottom : solid 1px var(--blue-blue-5);
    box-shadow    : inset 0px -3px 19px 0 rgb(0 0 0 / 6%);
}

.dark .eventLandingSideBar .MuiDrawer-paper {
	background    : var(--black);
	border-top    : solid 1px var(--brownish-grey-40);
	border-bottom : solid 1px var(--brownish-grey-40);
}

.eventLandingSideBar-container {
	height : 100%;
}
