.displayVideo {
	position         : absolute;
	height           : 100%;
	width            : 100%;
	background-color : #000;
	padding          : 20px;
	box-sizing       : border-box;
}

.displayVideo-container {
	display         : flex;
	justify-content : space-around;
	gap             : 20px;
	height          : 100%;
}

.layout1-screen1 {
	align-items : center;
	flex        : 1;
}

.layout2-screen1 {
	flex : 8;
}

.layout2-screen2 {
	flex : 4;
}

.layout3_1-screen1 {
	flex : 8;
}

.layout3_1-screen2-screen3 {
	display         : flex;
	flex-wrap       : wrap;
	flex            : 4;
	gap	            : 20px;
	justify-content : space-around;
	flex-direction  : column;
}

.layout3_1-screen2 {
	flex   : 5; 
}

.layout3_1-screen3 {
	flex   : 5; 
}

.layout3_2-screen1-screen3 {
	display         : flex;
	flex            : 8;
	flex-wrap       : wrap;
	gap	            : 20px;
	justify-content : space-around;
	flex-direction  : column;
}

.layout3_2-screen1 {
	flex   : 6;
}

.layout3_2-screen3 {
	flex   : 4;
}

.layout3_2-screen2 {
	flex : 4;
}

.layout4-screen1-screen3-screen4 {
	display         : flex;
	flex-wrap       : wrap;
	flex            : 8;
	gap	            : 20px;
	justify-content : space-around;
	flex-direction  : column;
}

.layout4-screen1 {
	flex   : 6;
}

.layout4-screen3-screen4 {
	display : flex;
	flex    : 4;
    gap     : 20px;
}

.layout4-screen3 {
	flex : 1;
}

.layout4-screen4 {
	flex : 1;
}

.layout4-screen2 {
	flex : 4;
}

.displayVideo-pa-avatar {
	position      : absolute;
	top           : 50%;
	left          : 50%;
	transform     : translate(-50%, -50%);
	border-radius : 100%;
	height        : 80%;
	width         : auto;
	max-height    : 180px;
	max-width     : 180px;
}

.displayVideo-pa-name {
	font-weight   : 600;
	color         : #FFF;
	overflow      : hidden;
	text-overflow : ellipsis;
	text-shadow   : 0 1px 2px rgb(0 0 0 / 60%), 0 0 2px rgb(0 0 0 / 30%);
	white-space   : nowrap;
	position      : absolute;
	bottom        : 15px;
	left          : 20px;
	margin        : 0px;
	background    : rgba(0,0,0,.5);
    padding       : 5px 5px;
    max-width     : calc(100% - 50px);
}

.displayVideo .video-container {
	position         : relative;
	background-color : #151515;
	border-radius    : 8px;
	overflow         : hidden;
	height           : 100%;
}

.displayVideo .video-container video {
	object-fit     : contain;
    height         : calc(100% + 2px);
    left           : -1px;
    pointer-events : none;
    position       : absolute;
    top            : -1px;
    width          : calc(100% + 2px);
}

.displayVideo-presenter-audio {
	display: none;
}

.comment-container {
	padding         : 20px;
	height          : 100%;
	box-sizing      : border-box;
	overflow        : auto;
}

.commentDisplay-container {
	width          : 100%;
    height         : 100%;
    overflow-y     : auto;
    padding-right  : 26px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing     : content-box;
	scroll-behavior: smooth;
}

.commentDisplay-header {
	width       : 100%;
	display     : flex;
	font-size   : 15px;
    font-weight : 600;
    line-height : 1.6;
	color       : #FFFFFF66;
}

.commentDisplay-commentCard {
	padding       : 0px 0px 20px 0px;
	box-sizing    : border-box;
	border-bottom : 1px solid rgba(175, 186, 201, 0.4);
	margin-top    : 13px;
}

.commentDisplay-commentCard-top-container {
	display     : flex;
	align-items : center;
}

.commentDisplay-commentCard-name-avatar-container {
	display     : flex;
	flex        : 1;
	align-items : center;
}

.commentDisplay-commentCard-name {
	font-size      : 14px;
    font-weight    : 600;
    line-height    : 1.6;
	color          : #FFF;
	margin         : 0px 10px;
	white-space    : nowrap;
	overflow       : hidden;
	text-overflow  : ellipsis;
}

.commentDisplay-commentCard-avatar {
	width         : 30px;
	height        : 30px;
	border-radius : 100%;
}

.commentDisplay-commentCard-time {
	font-size      : 12px;
    word-break     : break-word;
    font-weight    : 600;
    line-height    : 1.42;
	color          : #727272;
	display        : inline-flex;
	align-items    : center;
}

.commentDisplay-commentCard-time img {
	margin : 0px 7px;
}

.commentDisplay-commentCard-comment {
	font-size      : 14px;
    word-break     : break-word;
    line-height    : 1.6;
	color          : #FFFFFF66;
	margin-top     : 20px
}

.poll-container {
	padding         : 20px;
	height          : 100%;
	box-sizing      : border-box;
	overflow        : auto;
}

.pollDisplay-container {
	display        : flex;
    flex-direction : column;
    max-height     : 100%;
}

.pollDisplay-top-container {
	flex-shrink : 0;
}

.pollDisplay-question-title {
	font-size     : 14px;
    line-height   : 1.42;
	color         : #FFFFFF66;
	margin-bottom : 7px;
}

.pollDisplay-question {
    word-break    : break-word;
    font-weight   : 600;
    line-height   : 1.4;
	color         : #FFF;
	font-size     : 17px;
	margin-bottom : 30px;
}

.pollDisplay-view-conatiner {
	display     : flex;
	font-size   : 15px;
    font-weight : 600;
    line-height : 1.6;
	color       : #FFFFFF66;
}

.pollDisplay-totalVotes {
	flex : 1;
}

.pollDisplay-viewType {
	flex       : 1;
	text-align : right;
}

.pollDisplay-graph-container {
	overflow        : hidden auto;
	box-sizing      : content-box;
	padding-right   : 26px;
	width           : 100%;
	scroll-behavior : smooth;
}

.rtl .pollDisplay-graph-container {
	padding-right  : 0px;
	padding-left   : 26px;
}

.pollDisplay-bars-optionNo {
	font-size     : 14px;
    word-break    : break-word;
	font-weight   : 600;
    line-height   : 1.42;
	color         : #FFFFFF66;
	margin-bottom : 8px;
}

.pollDisplay-bars-option-container {
	display       : flex;
	font-size     : 14px;
    word-break    : break-word;
    line-height   : 1.38;
	color         : #FFFFFF8C;
	padding       : 14px 15px;
    border-radius : 8px;
    border        : solid 1px #FFFFFF66;
    position      : relative;
}

.pollDisplay-bars-option {
	flex : 10;
}

.pollDisplay-bars-percent {
	flex        : 1;
    text-align  : right;
	font-weight : 600;
}

.pollDisplay-bars-container {
	margin : 20px 0px;
}

.pollDisplay-pie-container {
	display  : flex;
	position : relative;
}

.pollDisplay-pie-graph {
	height   : 350px;
    position : relative;
	flex     : 1;
	position : sticky;
    top      : 10%;
}

.pollDisplay-pie-total {
	font-size   : 26px;
    color       : #FFF;
    text-align  : center;
    font-weight : 400;
    line-height : 1.38;
	font-weight : 600;
}

.pollDisplay-pie-total-votes{
	font-size   : 14px;
    font-weight : 600;
    line-height : 1.6;
	color       : #FFFFFF66;
}

.pollDisplay-pie-legends {
	flex : 1;
}

.pollDisplay-pie-option-container {
	display : flex;
	flex    : 10;
}

.pollDisplay-pie-legend-opt {
	display       : flex;
	gap           : 4px;
	padding       : 20px 0px;
	border-bottom : solid 1px rgba(175, 186, 201, 0.4);
}

.pollDisplay-pie-legend-icon {
	display    : inline-block;
    width      : 16px;
    min-width  : 16px;
    height     : 10px;
    min-height : 10px;
    margin     : 6px 10px 0px 0px;

}

.pollDisplay-pie-option, .pollDisplay-pie-percent {
	font-size   : 14px;
    word-break  : break-word;
    font-weight : 400;
    line-height : 1.6;
	color       : #FFF;
}

.pollDisplay-pie-percent {
	font-weight : 600;
}
