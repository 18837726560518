.landingTableHeader-container {
	padding          : 14px var(--xl);
	background-color : var(--blue-blue-5);
	border-radius    : 10px;
}

.dark .landingTableHeader-container {
	background-color : #222222;
}

.landingTableHeader-container > div {
	padding : 0px 14px 0px 0px;
}

.rtl .landingTableHeader-container > div {
	padding : 0px 0px 0px 14px;
}

.landingTableHeader-container p {
	text-transform : uppercase;
	color          : var(--light-grey-blue);
}

.dark .landingTableHeader-container p {
	color : var(--dark-grey);
}
