.goLiveApproved-title {
	margin-top	: 90px;
	font-size   : 21px;
}

.goLiveApproved-progress-container {
	position      : relative;
	display       : inline-block;
	margin-top    : 50px;
	margin-bottom : 50px;
}

.goLiveApproved-start-btn {
	height : 50px;
}