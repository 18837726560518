.speakRequestModal {
	padding   : var(--xxl);
	max-width : 380px;
	width     : 380px;
}

.speakRequestModal-progress-container {
	margin-top    : 50px;
	margin-bottom : 50px;
}

.speakRequestModal-progress-container.not-approved {
	transform     : rotate(230deg);
}

.speakRequestModal-btn-cancel {
	padding-right : var(--m);
}

.rtl .speakRequestModal-btn-cancel {
	padding-left  : var(--m);
	padding-right : 0px;
}

.speakRequestModal-btn-back {
	padding-left: var(--m);
}

.rtl .speakRequestModal-btn-back {
	padding-right : var(--m);
	padding-left  : 0px;
}