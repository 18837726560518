.toast-container {
	width            : 25%;
	min-width        : 300px;
	display          : flex;
	padding          : 15px 20px;
	box-shadow       : 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border           : solid 1px var(--blue-blue-5);
	border-radius    : 10px;
	background-color : var(--white);
}

.toast-container.success {
	box-shadow       : 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border           : solid 1px var(--blue-blue-5);
	background-color : var(--kermit-green);
}

.toast-container.error {
	box-shadow       : 0 0 20px 0 rgba(0, 0, 0, 0.2);
	border           : solid 1px var(--blue-blue-5);
	background-color : var(--dark-red);
}

.dark .toast-container.error {
	background-color : var(--dark-red);
}

.dark .toast-container {
	background-color : #555555;
	border           : solid 1px #646464;
	box-shadow       : none;
}

.toast-msg {
	font-family      : var(--regular);
	font-size        : 14px;
	color            : var(--grey-light);
	width            : 85%;
	white-space      : pre-wrap;
}

.toast-msg.success,.error {
	color : var(--white);
}

.dark .toast-msg {
	color :  var(--white-light);
}

.toast-action {
	width           : 15%;
	display         : flex;
	justify-content : flex-end;
	align-items     : bottom;
}

.toast-action-btn {
	font-family    : var(--bold);
	font-size      : 12px;
	font-stretch   : normal;
	font-style     : normal;
	line-height    : 1.42;
	letter-spacing : normal;
	text-align     : center;
	color          : var(--light-grey-blue);
	cursor         : pointer;
	border         : none;
	background     : none;
}

.toast-action-btn.success {
	color : var(--white);
}

.dark .toast-action-btn {
	color : var(--white-40);
}

.toast-action-btn.error {
	color : var(--white);
}
