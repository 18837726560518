.liveCall.page-parent {
	background-color : var(--black);
} 

.liveCall-statusbar {
	color       : var(--white);
	position    : fixed;
	font-size   : 16px;
	font-family : var(--semibold);
	top         : var(--xxl);
	left        : var(--xxl);
	display     : flex;
	align-items : center;
	z-index     : 10;
}

.liveCall-statusbar p {
	color : var(--white);
}

.liveCall .video-container {
	position         : relative;
	height           : 100%;
	background-color : var(--white-10);
}

.liveCall-video-disabled {
	position   : absolute;
	top        : 50%;
	left       : 50%;
	transform  : translate(-50%, -50%);
	text-align : center;
}

.liveCall-video-disabled-text {
	font-size      : 18px;
	font-family    : var(--semibold);
	margin-top     : var(--xxl);
	font-stretch   : normal;
	font-style     : normal;
	line-height    : 1.27;
	letter-spacing : normal;
	text-align     : center;
	color          : #636363;
}

.liveCall-live-icon {
	width            : 15px;
	height           : 15px;
	border-radius    : 100%;
	background-color : var(--kermit-green);
	display          : inline-block;
	margin-right     : var(--m);
}

.rtl .liveCall-live-icon {
	margin-left: var(--m);
}
