.language .MuiListItem-root:first-child {
	padding : 0px 0px 20px;
}

.language .MuiListItem-root:last-child {
	border-bottom  : none;
	padding-bottom : 0px;
}

.language .MuiListItem-root {
	padding : 20px 0px;
}

.language-title {
     font-family    : var(--bold);
     text-transform : uppercase;
 }

.language-down-icon {
	position : relative;
	bottom   : 2px;
}

.language-name {
	font-family : var(--semibold);
	cursor      : pointer;
}

.language-container {
	width : auto;
}
