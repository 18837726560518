.eventLandingTopBar-container {
	box-shadow       : 0 3px 19px 0 rgba(0, 0, 0, 0.06);
	border-bottom    : solid 1px var(--blue-blue-5);
	background-color : var(--white);
	padding          : var(--xxl) 34px;
	max-height       : var(--inevent-topbar-height);
    height           : var(--inevent-topbar-height);
}

.dark .eventLandingTopBar-container {
	background-color : var(--black);
	border-bottom    : none;
	box-shadow       : none;
}
