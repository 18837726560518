.allEvents-header {
	font-family : var(--semibold);
	color       : var(--light-grey-blue);
}

.dark .allEvents-header {
	color : #EBEBEB66;
}

.allEvents-no-event-text {
	font-family : var(--semibold);
	color       : var(--light-grey-blue-40);
	margin-left : var(--xxl2);
}

.dark .allEvents-no-event-text {
	color : var(--white-10);
}

